<template>
  <v-dialog v-model="visible" width="800" @click:outside="closeDialog" @keydown.esc="closeDialog">
    <v-card>
      <v-card-title class="card-title elevation-3">
        <span class="mr-3">Добавить запись</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-5">
        <v-container fluid>
          <v-form ref="form" v-model="valid">
            <v-text-field
              v-model="newRecord.name"
              label="Заголовок"
              :rules="[v => !!v || 'Обязательное поле']"
            />
            <div class="d-flex">
              <v-select
                v-model="newRecord.typeId"
                :items="filtersDictionaries.newsTypes"
                item-value="value"
                item-text="label"
                label="Тип"
                :rules="[v => ![null, undefined].includes(v) || 'Обязательное поле']"
                style="padding-right: 5px"
              />
              <v-select
                v-model="newRecord.statusId"
                :items="filtersDictionaries.newsStatuses"
                item-value="value"
                item-text="label"
                label="Статус"
                style="padding-left: 5px"
                :rules="[v => ![null, undefined].includes(v) || 'Обязательное поле']"
              />
            </div>
            <v-autocomplete
              v-model="newRecord.catalogId"
              :items="catalogs"
              item-text="nameRu"
              item-value="id"
              label="Каталог"
              @update:search-input="searchItems"
            />
            <CmImageUploader @set="handleImage" style="padding-left: unset" />
            <div class="image-preview" v-if="newRecord.photo && !tempImage">
              <v-btn class="image-preview-close" icon @click="newRecord.photo = null">
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-img :src="`${getEnv('VUE_APP_URL_MINIO')}/ecom/${newRecord.photo}`" />
            </div>
            <div class="image-preview" v-if="tempImage">
              <v-btn class="image-preview-close" icon @click="tempImage = null">
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-img :src="tempImage.url" />
            </div>
            <quill-editor
              v-model="newRecord.description"
              style="padding-top: 20px"
              :options="options"
              :content="newRecord.description"
              :class="{ 'error-editor': editorError }"
            />
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end pb-5">
        <v-btn text :disabled="loading" @click="$emit('close')">
          Отменить
        </v-btn>
        <v-btn
          :disabled="loading"
          @click="saveRecord"
          color="#007450"
          style="color: #FFFFFF; border-radius: 18px"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import getEnv from "../../../utils/env";

export default {
  name: "NewsDialog",
  props: {
    visible: {
      default: false
    },
    record: {
      default: null,
      type: [Object, null]
    },
    filtersDictionaries: {
      default: null,
      type: [Object, null]
    },
    loading: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      valid: false,
      newRecord: {
        photo: null,
        description: null,
        name: null,
        catalogId: null,
        statusId: null,
        typeId: null
      },
      tempImage: null,
      options: {},
      editorError: false,
      types: [],
      statuses: [],
      catalogs: []
    };
  },
  watch: {
    record: {
      handler(val) {
        if (val) {
          this.newRecord = { ...val };
        }
      },
      immediate: true
    }
  },
  created() {
    this.fetchEnums();
  },
  methods: {
    getEnv,
    async fetchEnums() {
      await Promise.all([
        this.$newsService.get("/crm/types").then(res => {
          this.types = res.content;
        }),
        this.$newsService.get("/crm/statuses").then(res => {
          this.statuses = res.content;
        }),
        this.$newsService.get(`/crm/byId?id=${this.record.id}`).then(res => {
          console.log(res.content, "???");
          this.newRecord.catalogId = res.content?.catalogId;
          this.newRecord.catalogName = res.content?.catalogName;
          this.record.catalogName = res.content?.catalogName;
        })
      ]);
      await this.$catalogService
        .get(`/crm-catalogs/search?name=${this.record.catalogName}`)
        .then(res => {
          this.catalogs = res;
        });
    },
    closeDialog() {
      this.$emit("close");
    },
    saveRecord() {
      if (this.$refs.form.validate() && (this.newRecord.photo || this.tempImage)) {
        if (this.tempImage) {
          this.newRecord.photo = this.tempImage.file;
        }
        this.$emit("save", this.newRecord);
      } else {
        this.$toast.warning("Заполните все поля");
        if (!this.newRecord.description) {
          this.editorError = true;
          setTimeout(() => {
            this.editorError = false;
          }, 3000);
        }
      }
    },
    async handleImage(image) {
      this.tempImage = image;
    },
    searchItems($event) {
      console.log($event, "$event");
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.timerId = setTimeout(() => {
        this.$catalogService.get(`/crm-catalogs/search?name=${$event || ""}`).then(res => {
          this.catalogs = res;
        });
      }, 500);
    }
  }
};
</script>

<style scoped lang="scss">
.image-preview {
  display: flex;
  max-width: 60%;
  position: relative;

  &-close {
    position: absolute;
    top: -10px;
    right: -10px;
    background: white;
    opacity: 0.8;
    z-index: 9;
  }
}

.quill-editor {
  position: relative;
  margin-bottom: 20px;
  max-width: 836px;
}

.ql-editor {
  min-height: 300px;
}
.error-editor {
  border: 1px solid red;
}
</style>
